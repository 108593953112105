<template>
    <div class="form-group" :class="{ 'mb-3': size != 'sm' }">
        <label v-if="label" for="phone" class="mb-2">{{ label }}</label>
        <div class="input-group">
            <input :minlength="min" :readonly="readOnly" :required="required" :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)" :placeholder="placeholder
                " :type="type" class="fw-400 fs-14 form-control primary-input"
                :class="{ 'is-invalid': errors.length > 0, 'py-2': size == 'sm' }">
            <span v-if="appendText" class="input-group-text text-primary">{{ appendText }}</span>

            <span class="invalid-feedback" v-for="(message, index) in  errors" :key="index">
                {{ message }}
            </span>

        </div>
    </div>
</template>
<script>
export default {
    props: {
        errors: {
            type: Array,
            default: () => {
                return []
            }
        },
        appendText: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: "text"
        },
        class: {
            type: String
        },
        size: {
            type: String,
            default: "normal"
        }, modelValue: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        min: {
            type: Number,
            default: 0
        }
    },
    // setup() {
    //     const value = ref("")
    //     return {
    //         value
    //     }
    // },
}
</script>
<style scoped>
.primary-input {
    background-color: #F1F3FA;
    padding: .75rem 1rem;
    border: 0;
    border-radius: 10px;
}

.input-group-text {
    background-color: #F1F3FA;
    border: 0;
    border-radius: 10px;

}

.primary-input:focus {
    background-color: #F1F3FA;
    border: none;
    outline: none;
}

.primary-input.is-invalid {
    border: 1px solid #D84242;
    background-color: #D842421A;
}

.invalid-feedback {
    margin-top: .5rem;
    font-family: 'Noto Sans Myanmar';
    color: #D84242;
    font-size: 13px;
    font-weight: 400;
}
</style>