<template>
    <div class="p-4">
        <div class="accordion accordion-flush" id="accordionExample">
            <div class="accordion-item" v-for="(rule, index) in rules" :key="index">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
                        {{ rule.title }}
                    </button>
                </h2>
                <div :id="'collapse' + index" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div class="accordion-body">
                        {{ rule.content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        rules() {
            return this.$store.state.index.rules
        }
    }
}
</script>
