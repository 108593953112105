import { createApp } from 'vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import App from './App.vue'
import router from './router';
import store from "./store";
import helper from './helpers/helper'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlus, faPhone, faHome, faUserCircle, faChevronLeft, faTimes, faUser, faCircleCheck, faCircleExclamation, faArrowRightFromBracket, faCamera } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
library.add(faPlus,faPhone,faUserCircle,faHome,faChevronLeft,faTimes,faUser,faClock,faCircleCheck,faCircleExclamation,faArrowRightFromBracket, faCamera)

import FormGroup from './components/FormGroup.vue';
import PrimaryButton from './components/buttons/PrimaryButton.vue';
import SecondaryButton from './components/buttons/SecondaryButton.vue';
import OrdinaryButton from './components/buttons/OrdinaryButton.vue';
import OutlineButton from './components/buttons/OutlineButton.vue';
import 'animate.css';


const app = createApp(App)
app.config.globalProperties.$helper = helper

app.component('PrimaryButton', PrimaryButton)
app.component('OutlineButton', OutlineButton)
app.component('OrdinaryButton', OrdinaryButton)
app.component('SecondaryButton', SecondaryButton)


app.component('font-awesome-icon', FontAwesomeIcon)
app.component('FormGroup',FormGroup)
app.use(router)
app.use(store)

app.mount('#app')
