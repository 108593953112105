import userService from "@/services/user.service";

export const index = {
  state: {
    loading: 0,
    alert: null,
    toastMessage: null,
    homeBannerText: null,
    homeBannerImages: [],
    serviceBannerText: null,
    serviceBannerImages: [],
    rules:[]
  },
  actions: {
    loading({ commit }) {
        commit('increaseLoading');
    },
    loadingComplete({ commit }) {
        commit('decreaseLoading');
    },
    showAlert({ commit }, alert) {
      commit('showAlert',alert)
    },
    hideAlert({ commit }) {
      commit('hideAlert')
    },
    showToast({ commit }, message) {
      commit('showToast',message)
    },
    hideToast({ commit }) {
      commit('hideToast')
    },
    getHomeBanner({ commit }) {
      userService.getHomeBanner().then((response) => {
        commit('updateHomeBanner', {
            images: response.data.data,
            text:response.data.title
        })
        return Promise.resolve(response);
      })
    },
    getServiceBanner({ commit }) {
      userService.getServiceBanner().then((response) => {
        commit('updateServiceBanner', {
            images: response.data.data,
            text:response.data.title
        })
        return Promise.resolve(response);
      })
    },
    getRules({ commit }) {
      userService.getAgentRules().then((response) => {
        commit('updateAgentRules', response.data.data)
        return Promise.resolve(response);
      })
    }
  },
  mutations: {
    increaseLoading(state) {
        state.loading++;
    },
    decreaseLoading(state) {
        state.loading--;
    },
    showAlert(state,alert) {
      state.alert = alert
    },
    hideAlert(state) {
      state.errorMessage = null
    },
    showToast(state,message) {
      state.toastMessage = message
    },
    hideToast(state) {
      state.toastMessage = null
    },
    updateHomeBanner(state, data) {
      state.homeBannerImages = data.images
      state.homeBannerText = data.text
    },
    updateServiceBanner(state, data) {
      state.serviceBannerImages = data.images
      state.serviceBannerText = data.text
    },
    updateAgentRules(state, data) {
      state.rules = data
    }
  }
};