import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://aladdin.micropos.app/api/agent/'
const API = 'https://aladdin.micropos.app/api/'

class UserService {

  getUserInfo() {
    return axios.get(API_URL + 'info', { headers: authHeader() });
  }

  applyBuying(data) {
    return axios.post(API_URL + 'apply/buy',data, { headers:authHeader() })
  }

  getPendingWithDrawList() {
    return axios.get(API_URL + 'buying/list',{headers:authHeader()})
  }

  getWithDrawTransactionDetail(id) {
    return axios.get(API_URL + 'buying/list/' + id,{headers:authHeader()})
  }

  getPendingDepositList() {
    return axios.get(API_URL + 'selling/list',{headers:authHeader()})
  }

  getDepositTransactionDetail(id) {
    return axios.get(API_URL + 'selling/list/' + id,{headers:authHeader()})
  }
  
  getPendingTopupList() {
    return axios.get(API_URL + 'agent_pending_cash_out_list',{headers:authHeader()})
  }

  getWithDrawDetail(id) {
    return axios.get(API_URL + 'agent_pending_cash_in_detail/'+id,{headers:authHeader()})
  }

  getTopupDetail(id) {
    return axios.get(API_URL + 'agent_pending_cash_out_detail/'+id,{headers:authHeader()})
  }

  confirmWithDraw(id) {
    return axios.post(API_URL + 'buying/list/'+id+'/confirm',{},{headers:authHeader()})
  }

  rejectWithDraw(id,data) {
    return axios.post(API_URL + 'buying/list/'+id+'/reject',data,{headers:authHeader()})
  }


  confirmDeposit(id) {
    return axios.post(API_URL + 'selling/list/'+id+'/confirm',{},{headers:authHeader()})
  }

  applySelling(data) {
    return axios.post(API_URL + 'apply/sell',data, { headers:authHeader() })
  }

  applyOnHold() {
    return axios.post(API_URL + 'apply/hold',{}, { headers:authHeader() })
  }

  rejectDeposit(id,data) {
    return axios.post(API_URL + 'selling/list/'+id+'/reject',data,{headers:authHeader()})
  }



  getPaymentMethods() {
    return axios.get(API + 'payment-method');
  }
  

  getHomeBanner() {
    return axios.get(API + 'slide/home');
  }

  getServices() {
    return axios.get(API + 'service');
  }

  getServiceBanner() {
    return axios.get(API + 'slide/service');
  }

  getTransactionHistory() {
    return axios.get(API_URL + 'transaction/history', {headers:authHeader()})
  }

  getAgentRules() {
    return axios.get(API_URL + 'rule')
  } 

  get2DCommisions() {
    return axios.get(API_URL + 'commision/twod',{headers:authHeader()});
  }

  get3DCommisions() {
    return axios.get(API_URL + 'commision/threed',{headers:authHeader()});
  }

  claimCommision(id) {
    return axios.post(API_URL + 'commision/'+id+'/claim',{},{headers:authHeader()})
  }

}

export default new UserService();