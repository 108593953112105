<template>
    <div class="toast-box rounded-3 p-4" v-if="alert">
        <font-awesome-icon icon="fa-solid fa-check-circle" v-if="alert.type == 'success'" size="2xl" />

        <font-awesome-icon icon="fa-solid fa-circle-exclamation" v-else size="2xl" />
        <p class="mb-0 fw-400 fs-14 text-center">{{ alert.message }}</p>
    </div>
</template>
<script>
export default {
    props: {
        alert: {
            type: Object,
            default: () => {
                return {
                    message: null,
                    type: null
                }
            }
        }
    }
}
</script>
<style scoped>
.toast-box {
    background-color: #000000B2;
    height: 15vh;
    max-width: 60vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    color: white;
}
</style>