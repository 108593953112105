<template>
    <div class="vh-100 index">
        <div class="banner">
            <img src="@/assets/index-logo.svg" alt="">
        </div>
        <div class="content p-4 d-flex flex-column">
            <div class="mt-auto mx-auto">
                <h1 class="fw-600 fs-18 text-white text-center">Welcome to Aladdin 2D</h1>
                <p class="fw-700 fs-14">လွယ်လွယ်ကစား Aladdinနဲ့လွယ်လွယ်ပိုက်ဆံရ</p>
            </div>
            <router-link :to="{ name: 'login' }">
                <OrdinaryButton>အကောင့်ဝင်မည်</OrdinaryButton>
            </router-link>
            <router-link :to="{ name: 'register' }">
                <PrimaryButton class="btn-register">Agent အဖြစ်လျှောက်မည်</PrimaryButton>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        if (this.$store.state.auth.status.loggedIn) {
            // this.$router.push({ name: 'home' })
        }
    }
}
</script>
<style scoped>
.btn-register {
    margin-top: .8rem;
}

.banner {
    height: 55vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100vw 55vh;
    background: url('../assets/index-background.svg');
    position: relative;
}

.banner img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 10vh;
}

.content {
    height: 45vh;
    width: 100vw;
}

.content p {
    color: #FFFFFFCC;
}

.index {
    background-color: #0B1C30;

}
</style>
