<template>
    <div class="d-flex flex-column" style="height: 94vh;">
        <RemainingAmount />
        <template v-if="transaction">
            <div class="p-3">
                <div class="">
                    <div class="text-center">
                        <img src="@/assets/cash-out-rounded.svg" style="width: 60px;" class="mb-3" alt="">
                        <h1 class="fw-600 fs-18 mb-2">{{ transaction.amount }} Unit</h1>
                        <span class="fw-400 fs-13">Unit အရောင်း</span>
                        <div>
                            <template v-if="transaction.remaining_time == 0">
                                <font-awesome-icon icon="fa-regular fa-clock" class="text-danger me-2"></font-awesome-icon>
                                <span class="fw-600 fs-14 text-danger">{{
                                    $helper.formattedTimer(transaction.remaining_time)
                                }}</span>
                            </template>
                            <template v-else>
                                <font-awesome-icon icon="fa-regular fa-clock" class="text-primary me-2"></font-awesome-icon>
                                <span class="fw-600 fs-14 text-primary">{{
                                    $helper.formattedTimer(transaction.remaining_time)
                                }}</span>
                            </template>

                        </div>
                    </div>
                    <div class="horizontal-line my-4"></div>
                    <div>
                        <h1 class="fw-600 fs-14">ဘောက်ချာအသေးစိတ်</h1>

                        <div class="row mb-4">
                            <div class="col-6">
                                <span class="fw-400 fs-13">ဓာတ်ပုံ</span>
                            </div>
                            <div class="col-6 text-end ">
                                <img :src="transaction.user.image" class="w-25" alt="">
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-6">
                                <span class="fw-400 fs-13">နာမည်</span>
                            </div>
                            <div class="col-6 text-end ">
                                <span class="fw-400 fs-14">{{ transaction.user.name }}</span>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-6">
                                <span class="fw-400 fs-13">ဖုန်းနံပါတ်</span>
                            </div>
                            <div class="col-6 text-end ">
                                <span class="fw-400 fs-14">{{  transaction.user.phone }}</span>
                            </div>
                        </div>


                        <div class="row mb-4">
                            <div class="col-6">
                                <span class="fw-400 fs-13">လုပ်ဆောင်ချိန်</span>
                            </div>
                            <div class="col-6 text-end ">
                                <span class="fw-400 fs-14">{{ $helper.formattedDateTime(transaction.time) }}</span>

                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-6">
                                <span class="fw-400 fs-13">ဘောက်ချာနံပါတ်</span>
                            </div>
                            <div class="col-6 text-end ">
                                <span class="fw-400 fs-14">{{ transaction.payment_transaction_id }}</span>
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-6">
                                <span class="fw-400 fs-13">Payအမျိုးအစား</span>
                            </div>
                            <div class="col-6 text-end ">
                                <span class="fw-400 fs-14">{{ transaction.payment.name }}</span>
                            </div>
                        </div>

                        <div class="row mb-4 justify-content-end">
                            <!-- <div class="col-6">
                                <span class="fw-400 fs-13">ဖုန်းနံပါတ်</span>
                            </div> -->
                            <div class="col-6 text-end text-primary ">
                                <span class="fw-600 fs-14">{{ transaction.user.phone }}</span>
                                <div>
                                    <a :href="'tel:' + transaction.user.phone"
                                        class="fw-600 fs-14 text-decoration-none">ဖုန်းခေါ်မည်</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3 mt-auto">
                <SecondaryButton class="mb-2" @click="showRejectConfirmationModal = true" color="text-danger">Reject
                </SecondaryButton>

                <PrimaryButton @click="showConfirmationModal = true">{{ $helper.formattedNumber(transaction.amount) }} Unit
                    သွင်းမည်</PrimaryButton>
            </div>

            <modal :show="showRejectConfirmationModal">
                <template v-slot:header>Reject ရန်သေချာပါသလား</template>

                <template v-slot:body>

                    <div class="form-group mb-3">
                        <label for="note" class="mb-2">အကြောင်းပြချက်</label>
                        <textarea v-model="note" name="note" class="form-control"
                            :class="{ 'is-invalid': errors.note.length > 0 }" rows="4" id=""></textarea>
                        <span v-for="(message, index) in errors.note" :key="index" class="invalid-feedback">
                            {{ message }}
                        </span>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <SecondaryButton @click="showRejectConfirmationModal = false">Cancel</SecondaryButton>
                        </div>
                        <div class="col-6">
                            <SecondaryButton @click="reject()" color="text-danger">Reject</SecondaryButton>
                        </div>
                    </div>
                </template>
            </modal>

            <ConfirmationBox @ok="transfer()" @cancel="showConfirmationModal = false" okButtonText="Unit သွင်းမည်"
                :show="showConfirmationModal" :title="$helper.formattedNumber(transaction.amount)"
                subtitle="Unit သွင်းမှာသေချာပါလား?" okButtonTextColor="text-primary" image="user.svg">
            </ConfirmationBox>
        </template>
    </div>
</template>
<script>
import RemainingAmount from '@/components/RemainingAmountComponent.vue';
import ConfirmationBox from '@/components/ConfirmationBoxComponent.vue';
import userService from '@/services/user.service';
import Modal from '@/components/ModalComponent.vue';
export default {
    components: {
        RemainingAmount,
        ConfirmationBox,
        Modal
    },
    data() {
        return {
            showConfirmationModal: false,
            showRejectConfirmationModal: false,
            id: this.$route.params.id,
            note: null,
            transaction: null,
            errors: {
                note: []
            }
        }
    },
    mounted() {
        this.fetchTransaction()
    },
    computed: {
        payment_method() {
            return this.$store.state.cash.paymentMethods.find(p => p.id == this.transaction.payment_method)
        }
    },
    methods: {
        counter() {
            let start = this.transaction.remaining_time;
            let that = this;
            let counterInterval = setInterval(function () {
                start--;
                that.transaction.remaining_time = start;
                if (start <= 0) {
                    clearInterval(counterInterval);
                }

            }, 1000);
        },
        fetchTransaction() {
            this.$store.dispatch('loading')
            userService.getDepositTransactionDetail(this.id).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.transaction = data.data
                console.log(this.transaction)
            })
        },
        transfer() {
            this.showConfirmationModal = false
            this.$store.dispatch('loading')
            userService.confirmDeposit(this.transaction.transaction_id).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showAlert', {
                        message: 'Unit သွင်းခြင်းအောင်မြင်သည်',
                        type: 'success'
                    })
                    this.$router.push({ name: 'home' })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        reject() {
            this.$store.dispatch('loading')
            userService.rejectDeposit(this.transaction.transaction_id, {
                note: this.note
            }).then(({ data }) => {
                this.showRejectConfirmationModal = false
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showAlert', {
                        message: 'Reject ခြင်းအောင်မြင်သည်',
                        type: 'success'
                    })
                    this.$router.push({ name: 'home' })
                }
            }).catch(({ response }) => {
                console.log(response)
                if (response.status == 422) {
                    this.errors = response.data.errors
                }
            })
        }
    }
}
</script>