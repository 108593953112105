<template>
    <div class="text-center">
        <img src="@/assets/alert.svg" alt="">
        <p class="fw-400 fs-13">Unit အသွင်းအထုတ်အမှားအယွင်းမရှိစေရန် အချက်အလက်များမှန်ကန်စွာဖြည့်ပါ</p>
    </div>

    <div class="d-flex align-items-center my-2">
        <img :src="payment.image" style="width: 50px;" class="me-3">
        <div>
            <h1 class="fw-700 fs-14 mb-0 text-decoration-none text-dark">{{ payment.name }}</h1>
        </div>
    </div>
    <form-group :errors="errors.receiver_account_name" v-model="receiver_account_name" label="Receiver Account Name"
        placeholder="Enter a receiver account name"></form-group>
    <form-group :errors="errors.receiver_account_phone" v-model="receiver_account_phone" label="Receiver Account Number"
        placeholder="Enter a receiver account number"></form-group>
    <form-group v-model="receiver_account_phone_confirmation" label="Confirm Receiver Account Number"
        placeholder="Re-enter a receiver account number"></form-group>

    <div class="row mt-auto">
        <div class="col-6">
            <SecondaryButton class="mt-auto" @click="savePaymentInfo()">Cancel</SecondaryButton>
        </div>
        <div class="col-6">
            <PrimaryButton class="mt-auto" @click="savePaymentInfo()">Save</PrimaryButton>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        payment: {
            type: Object
        }
    },
    data() {
        return {
            receiver_account_name: this.payment.receiver_account_name,
            receiver_account_phone: this.payment.receiver_account_phone,
            receiver_account_phone_confirmation: this.payment.receiver_account_phone,
            errors: {
                receiver_account_name: [],
                receiver_account_phone: [],
                receiver_account_phone_confirmation: [],
            }
        };
    },
    methods: {
        savePaymentInfo() {
            if (!this.receiver_account_name) {
                this.errors.receiver_account_name = ['Receiver account name is required']
                return;
            } else {
                this.errors.receiver_account_name = []
            }

            if (!this.receiver_account_phone) {
                this.errors.receiver_account_phone = ['Receiver account number is required']
                return;
            } else {
                this.errors.receiver_account_phone = []
            }

            if (this.receiver_account_phone != this.receiver_account_phone_confirmation) {
                this.errors.receiver_account_phone = ['Receiver account number confirmation does not match']
                return;
            } else {
                this.errors.receiver_account_phone = []
            }

            this.$emit('updatePaymentInfo', {
                ...this.payment,
                receiver_account_name: this.receiver_account_name,
                receiver_account_phone: this.receiver_account_phone
            })
        }
    }
}
</script>