<template>
    <!-- <div class="p-4">
        <div class="accordion accordion-flush" id="accordionExample">
            <div class="accordion-item" v-for="(commisions, index) in data" :key="index">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="'#collapse' + index" aria-expanded="true" aria-controls="collapseOne">
                        {{ index }}
                    </button>
                </h2>
                <div :id="'collapse' + index" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                    <div class="accordion-body">
                        <table class="table">
                            <thead>
                                <tr class="fw-600 fs-13">
                                    <th class="text-center">အချိန်</th>
                                    <th class="text-center">Commision</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in commisions" :key="index" class="fw-400 fs-12 text-center">
                                    <td>
                                        {{ item.title }}
                                    </td>
                                    <td>{{ item.commision }}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="p-3">
        <ul class="list-group list-group-flush" v-if="data.length > 0">
            <li class="list-group-item py-2" v-for="(list, index) in data" :key="index">
                <div class="d-flex mb-2">
                    <font-awesome-icon icon="fa-solid fa-user" class="text-primary"></font-awesome-icon>
                    <span class="fw-400 fs-13 ms-2">{{ list.customer.name }}</span>
                    <span class="fw-400 fs-13 ms-auto">{{ $helper.getDiffForHumans(list.time)
                    }}</span>
                </div>
                <div class="d-flex mb-1">
                    <div>
                        <h1 class="mb-0 fw-700 fs-16">{{ $helper.formattedNumber(list.amount) }} Unit</h1>
                        <span class="fw-600 fs-14 ms-auto text-primary">{{ list.section }}</span>
                    </div>
                    <div class="ms-auto" style="width:35%">
                        <PrimaryButton :ref="'claim-btn-' + list.transaction_id" :id="'claim-btn-' + list.transaction_id"
                            @click="claim(index)">
                            Claim
                        </PrimaryButton>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else class="mt-5 text-center">
            ဘာမှမရှိသေးပါ
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            data: [
            ]
        }
    }
    ,
    mounted() {
        this.fetCommisions()
    },
    methods: {
        fetCommisions() {
            this.$store.dispatch('loading')
            userService.get2DCommisions().then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.data = data.data
                console.log(this.data)
                console.log(data)
            }).catch(err => {
                console.log(err)
            })
        },
        claim(index) {
            let list = this.data[index]
            if (list.claimed) {
                return false
            }

            let id = list.transaction_id
            let button = document.getElementById('claim-btn-' + id)
            button.innerHTML = 'loading...'
            button.disabled = true
            userService.claimCommision(id).then(({ data }) => {
                if (data.success) {
                    this.data[index].claimed = true
                    button.innerHTML = 'Claimed'
                    button.disabled = false
                }
            }).catch(err => console.log(err))
        }
    }
}
</script>
<style>
.transaction-line {
    border: 1px solid #EBF0FD;
}
</style>