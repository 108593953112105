<template>
    <div>
        <div class="d-flex">
            <div @click="view = 1" :class="{ 'active': view == 1 }"
                class="col-6 d-flex align-items-center border-bottom border-2 justify-content-center py-2">
                <span class="fw-700 fs-15">
                    အသစ်
                </span>
                <div class="bg-danger text-white noti rounded-circle ms-2">
                    {{ pending_list.length }}
                </div>
            </div>
            <div @click="view = 2" :class="{ 'active': view == 2 }"
                class="col-6 d-flex align-items-center border-bottom  border-2 justify-content-center py-2">
                <span class="fw-700 fs-15">
                    အချိန်ကျော်လွန်
                </span>
                <div class="bg-danger text-white noti rounded-circle ms-2">
                    {{ due_list.length }}
                </div>
            </div>
        </div>
        <div class="p-3 mb-5">
            <ul class="list-group list-group-flush" v-if="view == 1">
                <li class="list-group-item py-2" v-for="(list, index) in pending_list" :key="index">
                    <div class="d-flex mb-2">
                        <font-awesome-icon icon="fa-solid fa-user" class="text-primary"></font-awesome-icon>
                        <span class="fw-400 fs-13 ms-2">{{ list.user.name }}</span>
                        <span class="fw-400 fs-13 ms-auto">{{ $helper.getDiffForHumans(list.time) }}</span>
                    </div>
                    <div class="d-flex mb-1">
                        <div>
                            <h1 class="mb-0 fw-700 fs-16">{{ $helper.formattedNumber(list.amount) }} Unit</h1>
                            <font-awesome-icon icon="fa-regular fa-clock" class="text-danger me-2"></font-awesome-icon>
                            <span class="fw-600 fs-14 ms-auto text-danger">{{ list.remaining_time }}</span>
                        </div>
                        <div class="ms-auto" style="width:35%">
                            <router-link :to="{ name: 'sell.transfer', params: { id: list.transaction_id } }">
                                <PrimaryButton>Unit သွင်းမည်</PrimaryButton>
                            </router-link>
                        </div>
                    </div>
                    <p>
                        <font-awesome-icon icon="fa-solid fa-circle-check" class="text-success me-2"></font-awesome-icon>
                        <span class="fw-400 fs-13">{{ $helper.formattedNumber(list.amount) }} Ks has been sent to your {{
                            list.payment.name }}.</span>
                    </p>
                </li>
            </ul>
            <ul class="list-group list-group-flush" v-if="view == 2">
                <li class="list-group-item py-2" v-for="(list, index) in due_list" :key="index">
                    <div class="d-flex mb-2">
                        <font-awesome-icon icon="fa-solid fa-user" class="text-primary"></font-awesome-icon>
                        <span class="fw-400 fs-13 ms-2">{{ list.user.name }}</span>
                        <span class="fw-400 fs-13 ms-auto">{{ $helper.getDiffForHumans(list.time) }}</span>
                    </div>
                    <div class="d-flex mb-1">
                        <div>
                            <h1 class="mb-0 fw-700 fs-16">{{ $helper.formattedNumber(list.amount) }} Unit</h1>
                            <font-awesome-icon icon="fa-regular fa-clock" class="text-danger me-2"></font-awesome-icon>
                            <span class="fw-600 fs-14 ms-auto text-danger">0:00</span>
                        </div>
                        <div class="ms-auto" style="width:35%">
                            <router-link :to="{ name: 'sell.transfer', params: { id: list.transaction_id } }">
                                <PrimaryButton>Unit သွင်းမည်</PrimaryButton>
                            </router-link>
                        </div>
                    </div>
                    <p>
                        <font-awesome-icon icon="fa-solid fa-circle-check" class="text-success me-2"></font-awesome-icon>
                        <span class="fw-400 fs-13">{{ $helper.formattedNumber(list.amount) }} Ks has been sent to your {{
                            list.payment.name }}.</span>
                    </p>
                </li>
            </ul>
        </div>


        <div style="position: fixed;bottom: 0;" class="w-100 p-3 bg-white border-top">
            <SecondaryButton @click="onStopSelling()" color="text-danger">Unitအရောင်း ရပ်နားရန်</SecondaryButton>
        </div>

        <ConfirmationBox @ok="stopSelling()" @cancel="confirmModal = false" :show="confirmModal" title="Confirmation"
            subtitle="Unitအရောင်း ရပ်နားရန် သေချာပါလား?" okButtonText="Yes" okButtonTextColor="text-danger">
        </ConfirmationBox>
    </div>
</template>
<script>
import userService from '@/services/user.service';
import ConfirmationBox from '@/components/ConfirmationBoxComponent.vue';
export default {
    components: {
        ConfirmationBox
    },
    data() {
        return {
            confirmModal: false,
            view: 1,
            pending_list: [
                // {
                //     id: 1,
                //     name: "Aung Kyaw",
                //     time: "5min ago",
                //     remaining_time: "3:59",
                //     amount: "1000",
                //     payment_type: "KBZ Pay"
                // },
                // {
                //     id: 2,
                //     name: "Aung Kyaw",
                //     time: "5min ago",
                //     remaining_time: "3:59",
                //     amount: "1000",
                //     payment_type: "KBZ Pay"
                // },
                // {
                //     id: 3,
                //     name: "Aung Kyaw",
                //     time: "5min ago",
                //     remaining_time: "3:59",
                //     amount: "1000",
                //     payment_type: "KBZ Pay"
                // }
            ],
            due_list: [
                // {
                //     id: 1,
                //     name: "Aung Kyaw",
                //     time: "5min ago",
                //     remaining_time: "3:59",
                //     amount: "1000",
                //     payment_type: "KBZ Pay"
                // },
                // {
                //     id: 2,
                //     name: "Aung Kyaw",
                //     time: "5min ago",
                //     remaining_time: "3:59",
                //     amount: "1000",
                //     payment_type: "KBZ Pay"
                // },
                // {
                //     id: 3,
                //     name: "Aung Kyaw",
                //     time: "5min ago",
                //     remaining_time: "3:59",
                //     amount: "1000",
                //     payment_type: "KBZ Pay"
                // }
            ],

        }
    },
    mounted() {
        this.fetchTopupList()
    },
    methods: {
        counter() {
            var that = this;
            this.pending_list.forEach((list, index) => {
                let start = list.remaining_time;
                let counterInterval = setInterval(function () {
                    start--;
                    that.pending_list[index].remaining_time = start;
                    if (start <= 0) {
                        that.due_list.push(that.pending_list[index])
                        that.pending_list.splice(index, 1)
                        clearInterval(counterInterval);
                    }

                }, 1000);
            })
        },
        fetchTopupList() {
            this.$store.dispatch('loading')
            userService.getPendingDepositList().then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.pending_list = data.data.pending_list
                this.due_list = data.data.due_list
                this.counter()
                console.log(data)
            })
        },
        onStopSelling() {
            if (this.pending_list.length + this.due_list.length > 0) {
                this.$store.dispatch('showAlert', {
                    message: 'Please complete the remaining transactions!'
                })
                return false;
            }
            this.confirmModal = true
        },

        stopSelling() {
            this.$store.dispatch('loading')
            userService.applyOnHold().then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$router.push({ name: 'home' })
                }
            }).catch((error) => {
                if (error.response.status == 403) {
                    this.$store.dispatch('showAlert', {
                        message: error.response.data.message
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
.noti {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
}

.active {
    color: #3367EE;
    border-color: #3367EE !important;
}
</style>