<template>
    <div v-show="!isEditingPaymentInfo">
        <div class="p-4 d-flex flex-column" style="height: 94vh;">
            <h1 class="fw-700 fs-18">Unit အသွင်းအထုတ်</h1>
            <p class="fw-400 fs-13">Unit အသွင်းအထုတ်လုပ်ရန် အချက်အလက်ဖြည့်ပါ</p>

            <div>
                <FormGroup v-model="form.minimum_amount" label="ရောင်းမည့်ပမာဏအနဲဆုံး" appendText="Unit"
                    :errors="errors.minimum_amount"></FormGroup>
                <FormGroup v-model="form.maximum_amount" label="ရောင်းမည့်ပမာဏအများဆုံး" appendText="Unit"
                    :errors="errors.maximum_amount"></FormGroup>
                <div class="d-flex">
                    <span class="fw-400 fs-13">လက်ကျန် Unit :</span>
                    <p class="fw-600 fs-13 ms-2">1,500,000 Unit</p>
                </div>
            </div>

            <div class="mb-4">
                <h1 class="fw-700 fs-14 mb-3">Pay အမျိုးအစား</h1>
                <div class="mt-2">
                    <div v-for="(payment, index) in user.payment_methods" :key="index" class="mb-3">
                        <div class="d-flex align-items-center mb-2">
                            <img :src="payment.image" style="width: 40px;" class="me-3">
                            <div>
                                <div v-if="payment.receiver_account_name && payment.receiver_account_phone">
                                    <h1 class="fw-700 fs-14 mb-0 text-decoration-none text-dark">{{
                                        payment.receiver_account_name }}</h1>
                                    <p class="fw-400 fs-12 mb-0">{{ payment.receiver_account_phone }}</p>
                                </div>
                                <h1 v-else class="fw-700 fs-14 mb-0 text-decoration-none text-dark">{{ payment.name }}</h1>

                            </div>
                            <div class="ms-auto">
                                <a @click="editPayment(payment)"
                                    v-if="payment.receiver_account_name != null && payment.receiver_account_phone != null"
                                    class="text-decoration-none fw-700 fs-14 me-2">ပြုပြင်မည်</a>
                                <font-awesome-icon @click="removePayment(index)" icon="fa-solid fa-times" size="lg"
                                    class="text-danger" />
                            </div>

                        </div>
                        <PrimaryButton
                            v-if="payment.receiver_account_name == null && payment.receiver_account_phone == null"
                            @click="editPayment(payment)">အချက်အလက်ဖြည့်မည်</PrimaryButton>
                    </div>
                </div>

                <a @click="showModal = true" class="text-decoration-none fw-700 fs-14 text-primary">
                    <font-awesome-icon icon="fa-plus fa-solid"></font-awesome-icon>
                    Pay အမျိုးအစားထည့်မည်
                </a>
                <br>
                <small class="text-danger" v-for="(message, index) in errors.payment_method" :key="index">
                    {{ message }}
                </small>
            </div>

            <div class="mb-4">
                <h1 class="fw-700 fs-14 mb-3">လုပ်ငန်းကြာချိန်</h1>
                <div class="row">
                    <div @click="this.form.duration = duration" v-for="(duration, key) in  duration_options" :key="key"
                        class="col-4 mb-2 text-center">
                        <div :class="{
                            'bg-primary text-white': form.duration == duration,
                            'text-primary bg-duration': form.duration != duration
                        }" class="text-center py-2 rounded">
                            {{ $helper.formattedNumber(duration) }} min
                        </div>
                    </div>
                    <small class="text-danger" v-for="(message, index) in errors.duration" :key="index">
                        {{ message }}
                    </small>
                </div>

            </div>

            <div class="mt-auto row">
                <div class="col-6">
                    <SecondaryButton @click="showConfirmationModal = true" class="fw-700 fs-14">နောက်သို့</SecondaryButton>
                </div>
                <div class="col-6">
                    <PrimaryButton class="fw-700 fs-14" @click="onSubmit()">စာတင်မည်</PrimaryButton>
                </div>
            </div>

            <modal-box @close="showModal = false" :show="showModal">
                <template v-slot:header>Pay အမျိုးအစားရွေးပါ</template>

                <template v-slot:body>
                    <div style="height: 80vh;">
                        <div v-for="(payment, index) in payments" :key="index" @click="addPayment(index)"
                            class="rounded-4 d-flex align-items-center border border-2 p-3 mb-3">
                            <img :src="payment.image" style="width: 40px;" class="me-3">
                            <h1 class="fw-700 fs-14 mb-0 text-decoration-none text-dark">{{ payment.name }}</h1>
                        </div>
                    </div>
                </template>
            </modal-box>
        </div>
    </div>

    <div v-if="isEditingPaymentInfo" class="p-4 d-flex flex-column" style="height: 94vh;">
        <edit-payment-info @updatePaymentInfo="updatePaymentInfo" :payment="editingPayment"></edit-payment-info>
    </div>

    <ConfirmationBox @ok="$router.push({ name: 'home' })" :show="showConfirmationModal" title="Confirmation"
        subtitle="Are you sure you want to discard changes?"></ConfirmationBox>

    <ConfirmationBox @cancel="submitConfirmModal = false" @ok="submit()" :show="submitConfirmModal" title="Confirmation"
        subtitle="Are you sure you want to submit?"></ConfirmationBox>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import ModalBox from '@/components/ModalComponent.vue';
import EditPaymentInfo from '@/components/EditPaymentInfo.vue';
import ConfirmationBox from '@/components/ConfirmationBoxComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        ModalBox,
        PrimaryButton,
        EditPaymentInfo, ConfirmationBox
    },
    data() {
        return {
            showModal: false,
            isEditingPaymentInfo: false,
            editingPayment: null,
            showConfirmationModal: false,
            submitConfirmModal: false,
            user: this.$store.state.auth.user,
            duration_options: [5, 10, 15],
            form: {
                minimum_amount: 5000,
                maximum_amount: 50000,
                duration: null,
            },
            errors: {
                minimum_amount: [],
                maximum_amount: [],
                duration: [],
                payment_method: []
            }
        }
    },
    mounted() {

        if (this.user && this.user.current_mode != 2) {
            this.form.minimum_amount = this.user.minimum_amount
            this.form.maximum_amount = this.user.maximum_amount
            this.form.duration = this.user.duration

            this.payments.forEach((p, index) => {
                let exists = this.user.payment_methods.find(sp => {
                    return sp.id == p.id;
                });

                if (exists != undefined) {
                    this.payments[index].is_selected = true
                    this.payments[index].receiver_account_name = exists.receiver_account_name
                    this.payments[index].receiver_account_phone = exists.receiver_account_phone
                }
            })

        }
    },
    computed: {
        selectedPayments() {
            return this.user.payment_methods
        },
        paymentInformation() {
            return JSON.parse(this.user.payment_information)
        },
        payments() {
            if (this.user.current_mode != 1) {
                return this.$store.state.cash.paymentMethods
            }
            return this.$store.state.cash.paymentMethods.map((p) => {
                let exists = this.user.payment_methods.find(sp => {
                    return sp.id == p.id;
                });

                if (exists != undefined) {
                    return {
                        id: p.id,
                        name: p.name,
                        image: p.image,
                        is_selected: true,
                        receiver_account_name: exists.receiver_account_name,
                        receiver_account_phone: exists.receiver_account_phone,
                    }
                } else {
                    return {
                        id: p.id,
                        name: p.name,
                        image: p.image,
                    }
                }
            });
        }
    },
    methods: {
        addPayment(index) {
            this.user.payment_methods.push(this.payments[index])
            console.log(this.user.payment_methods)

            this.showModal = false
        },
        editPayment(payment) {
            this.isEditingPaymentInfo = true
            this.editingPayment = payment
        },
        removePayment(index) {
            this.user.payment_methods.splice(index, 1)
        },
        updatePaymentInfo(payment) {
            let index = this.user.payment_methods.findIndex(p => p.id == payment.id)
            this.user.payment_methods[index] = payment
            this.isEditingPaymentInfo = false
        },
        validate() {
            let isValid = true;
            if (!(this.form.minimum_amount && this.form.minimum_amount > 0)) {
                this.errors.minimum_amount.push("Mininum amount is required")
                isValid = false
            } else {
                this.errors.minimum_amount = []
            }

            if (!(this.form.maximum_amount && this.form.maximum_amount > 0)) {
                this.errors.maximum_amount.push("Maximum amount is required")
                isValid = false
            } else {
                this.errors.maximum_amount = []
            }

            if (!(this.form.duration)) {
                this.errors.duration.push("Duration is required")
                isValid = false
            } else {
                this.errors.duration = []
            }

            if (this.selectedPayments.length < 1) {
                this.errors.payment_method.push("Payment method is required")
                isValid = false
            } else {
                this.errors.payment_method = []
            }

            return isValid;
        },
        onSubmit() {
            if (this.validate()) {
                this.submitConfirmModal = true
            }
        },
        submit() {
            console.log(this.user.payment_methods)
            userService.applyBuying({
                ...this.form,
                payment_method: this.user.payment_methods
            }).then(({ data }) => {
                console.log(data)
                if (data.success) {
                    this.$router.push({ name: 'home' })
                }
            }).catch(response => {
                console.log(response)
                if (response.status == 422) {
                    this.errors = response.data.errors
                    console.log(this.errors)
                }
            })
        }
    }
}
</script>
<style scoped> .bg-duration {
     background: #EBF0FD;
 }
</style>