import { createWebHistory, createRouter } from "vue-router";
import LoginView from "@/views/LoginView";
import RegisterView from "@/views/RegisterView";
import HomeView from "@/views/HomeView.vue";
import IndexView from "@/views/IndexView.vue";
import PendingView from "@/views/PendingView.vue";


import SellUnitIndexView from "@/views/SellUnit/IndexView.vue";
import SellUnitCreateView from "@/views/SellUnit/CreateView.vue";
import SellUnitListView from "@/views/SellUnit/ListView.vue";
import SellUnitPreviewView from "@/views/SellUnit/PreviewView.vue";
import SellUnitTransferView from "@/views/SellUnit/TransferView.vue";

import BuyUnitListView from "@/views/BuyUnit/ListView.vue";
import BuyUnitCreateView from "@/views/BuyUnit/CreateView.vue";
import BuyUnitTransferView from "@/views/BuyUnit/TransferView.vue";
import BuyUnitIndexView from "@/views/BuyUnit/IndexView.vue";

import ProfileView from "@/views/ProfileView.vue";
import ServicesView from "@/views/ServicesView.vue";

import TransactionHistoryView from "@/views/TransactionHistory.vue"
import RulesView from "@/views/RulesView.vue"

import CommisionsView from "@/views/CommisionsView.vue";





import middleware from "@/middleware";

const routes = [
   {
    path: "/",
    name: "index",
    component: IndexView,
    meta: {
      navbar: false,
      footerNavbar: false,
      backButton: true,
      requireAuth:false,
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:false,
    }
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:false,
    }
  },
  {
     path: "/home",
     name: "home",
     component: HomeView,
     meta: {
      navbar: false,
      footerNavbar: true,
      backButton: true,
      requireAuth:true,
     }
  },
   {
     path: "/pending",
     name: "pending",
     component: PendingView,
     meta: {
      navbar: false,
      footerNavbar: false,
      backButton: false,
      requireAuth:false,
     }
  },
    {
     path: "/buy",
     name: "buy",
     component: BuyUnitIndexView,
     meta: {
      title:"Unit အဝယ်",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
     }
  },
   {
     path: "/sell",
     name: "sell",
     component: SellUnitIndexView,
     meta: {
      title:"Unit အရောင်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth: true,
      mode:2,
     }
  },
  {
     path: "/sell/create",
     name: "sell.create",
     component: SellUnitCreateView,
     meta: {
      title:"Unit အရောင်း",
      navbar: true,
      footerNavbar: false,
      backButton: false,
      requireAuth:true,
      mode:2,
     }
  },
  {
     path: "/sell/list",
     name: "sell.list",
     component: SellUnitListView,
     meta: {
      title:"Unit အရောင်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      mode:2,
     }
  },
  {
     path: "/sell/preview",
     name: "sell.preview",
     component: SellUnitPreviewView,
     meta: {
      title:"Unit အရောင်း",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth: true,
      mode:2,
     }
  },
  {
     path: "/sell/:id/transfer",
     name: "sell.transfer",
     component: SellUnitTransferView,
     meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:true,
      mode:2,
     }
  },
  {
     path: "/buy/list",
     name: "buy.list",
     component: BuyUnitListView,
     meta: {
      title:"Unit အဝယ်",
      navbar: true,
      footerNavbar: false,
      backButton: true,
        requireAuth: true,
      mode:1,
      
     }
  },
   {
     path: "/buy/create",
     name: "buy.create",
     component: BuyUnitCreateView,
     meta: {
      title:"Unit အဝယ်",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth: true,
      mode:1,
      
     }
  },
  {
     path: "/buy/:id/transfer",
     name: "buy.transfer",
     component: BuyUnitTransferView,
     meta: {
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth: true,
      mode:1,
      
     }
   },
   {
     path: "/profile",
     name: "profile",
     component: ProfileView,
     meta: {
       title:"Profile",
      navbar: true,
      footerNavbar: true,
      backButton: false,
      requireAuth:false,
     },
  },
   {
     path: "/services",
     name: "services",
     component: ServicesView,
     meta: {
      title:"အကူအညီ",
      navbar: true,
      footerNavbar: true,
      backButton: false,
      requireAuth:false,
     },
  },
   {
     path: "/transaction/history",
     name: "transaction-history",
     component: TransactionHistoryView,
     meta: {
      title:"History",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:false,
     },
  },
  {
     path: "/commisions",
     name: "commisions",
     component: CommisionsView,
     meta: {
      title:"Commision",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:false,
     },
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesView,
    meta: {
      title:"Agent စည်းမျဥ်းစည်းကမ်းများ",
      navbar: true,
      footerNavbar: false,
      backButton: true,
      requireAuth:false,
     },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});
 
middleware(router)

export default router;