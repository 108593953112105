<template>
        <div class="col-6 text-center" @click="nrcBack('nrc_back')">
            <input class="d-none" type="file" capture="camera" ref="chooseImage" @change="imageChoosed('nrc_back')">
            <div class="choose-nrc rounded-4" v-if="form.nrc_back == null">
                <div class="d-flex justify-content-center align-items-center choose rounded-4">
                    <div class="text-center">
                        <img src="@/assets/camera.svg" alt="">
                        <p class="mb-0 fw-600 fs-13 text-primary">Use Camera</p>
                    </div>
                </div>
            </div>
            <div v-else>
                <img class="w-100 h-100 rounded-4" :src="preview.nrc_back" alt="">
            </div>
            <label class="fw-400 fs-13" for="">မှတ်ပုံတင်အနောက်ခြမ်း</label>
            <br>
            <small class="text-danger" :key="index" v-for="(message, index) in errors.nrc_back">
                {{ message }}
            </small>
        </div>
</template>

<script>
export default {
    data() {
        return {
            cameraId: null,
            form: {
                phone: null,
                name: null,
                contact_phone: null,
                profile: null,
                nrc_front: null,
                has_level_2: false
            },
            NrcBack:null,
            preview: {
                profile: null,
                nrc_front: null,
                nrc_back: null
            },
            errors: {},
        };
    },
    methods: {
        next() {
            this.$router.push({
                name: 'new-pin',
                params: {
                    phone: this.phone,
                    name: this.name
                }
            })
        },
        nrcBack() {
            // console.log(text);
            const inputElement = this.$refs.chooseImage;

            // Clear previously selected files, if any
            inputElement.value = '';

            // Set the capture attribute to "camera" to show only the camera option
            inputElement.setAttribute("capture", "camera");

            // Trigger the click event on the input element
            inputElement.click();
        },
        chooseImage(id) {
            this.$refs[id + '-input'].click()
        },
        imageChoosed(id) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.preview[id] = e.target.result;
            };
            this.form[id] = event.target.files[0]
            this.NrcBack = event.target.files[0];
            this.$store.dispatch('setdata', this.NrcBack);
        },
        capturedPhoto(data) {
            this.form[this.cameraId] = data.file
            this.preview[this.cameraId] = data.preview
            this.cameraId = null
        }
    },
}
</script>

<style scoped>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}

.choose-nrc {
    height: 120px;
}

.choose-nrc .choose {
    background-color: #F1F3FA;
    height: 100%;
}

input[type='checkbox'] {
    width: 20px;
    height: 20px;
}
</style>
