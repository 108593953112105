import { createStore } from "vuex";
import { auth } from "./auth.module";
import { index } from "./index.module";
import { cash } from "./cash.module";

export const Data = {
  state: {
    data: null,
    front:null,
  },
  getters: {
    getdata: state => state.data,
    getfront:state => state.front,
  },
  mutations: {
    setdata: (state, value) => {
      state.data = value;
    },
    setfront: (state, value) => {
      state.front = value;
    },
  },
  actions: {
    setdata: ({ commit }, value) => {
      commit('setdata', value);
    },
    setfront: ({ commit }, value) => {
      commit('setfront', value);
    },
  },
};

const store = createStore({
  modules: {
    auth,
    index,
    cash,
    data: Data,
  },
});

export default store;
