export default function RedirectIfAuthenticated(router) {
    /**
     * If the user is already authenticated he shouldn't be able to visit 
     * pages like login, register, etc...
     */
    router.beforeEach((to, from, next) => {
        // localStorage.removeItem('user')
        let token = localStorage.getItem('token')
        /**
         * Checks if there's a token and the next page name is none of the following
         */
        if (token && (to.name == 'login' || to.name == 'register' || to.name == 'index')) {
            router.push({name:'home'})
        }


        if ((!token) && to.meta.requireAuth) {
            // router.push({ name: 'login' })
        }

        next()
    })
}