<template>
    <div class="bg-thin d-flex p-3">
        <span class="fw-400 fs-13">လက်ကျန် Unit:</span>
        <span class="fw-600 fs-13 ms-2">{{ $helper.formattedNumber($store.state.auth.user.balance) }}
            Unit</span>
        <!-- <router-link class="fw-600 fs-13 text-decoration-none ms-auto" :to="{ name: 'top-up' }">ပြုပြင်မည်</router-link> -->
    </div>
</template>
<script>
export default {
    mounted() {
        console.log()
    }
}
</script>