<template>
    <button :type="type" :style="{ backgroundColor: backgroundColor }"
        class="btn font-poppins btn-light fw-600 rounded-3 fs-14 secondary-btn  w-100" :class="color">
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: "button"
        },
        color: {
            type: String,
            default: 'text-primary'
        },
        backgroundColor: {
            type: String,
            default: '#EBF0FD'
        }
    }
}
</script>
<style>
.secondary-btn {
    padding: .6rem 0 !important;
}
</style>