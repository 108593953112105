import moment from "moment";
export default {
    formattedNumber(num) {
        if (num == null) {
            return;
        }
        var str = num.toString().split('.');
        if (str[0].length >= 0) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 0) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    },
    formattedTime(time) {
        return moment(time, ['HH:mm']).format('hh:mm A');
    },
    formattedTimer(secs) {
        return moment.utc(secs*1000).format('mm:ss')
    },
    formattedDateTime(date_time) {
        return moment(date_time).format('D/MM/y hh:mm A');
    },
    getDayTime(time) {
        return moment(time).format('A');
    },
    getDiffForHumans(date_time) {
        return moment(date_time).fromNow()
    }
}