<template>
    <div class="text-dark">
        <div class="">
            <!-- <div style="display: flex; justify-content: center;">
                <div class="camera-button">
                    <button type="button" class="button is-rounded cam-button"
                        style="margin-left: 40%; background-color: white; border: 0px;" @click="toggleCamera">
                        <span v-if="!isCameraOpen"><img style="height: 25px;" class="button-img"
                                src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"></span>
                        <span v-else><img style="height: 25px;" class="button-img"
                                src="https://img.icons8.com/material-outlined/50/000000/cancel.png"></span>
                    </button>
                </div>
            </div> -->
            <div v-if="isCameraOpen" class="camera-box">
                <video ref="camera" autoplay playsinline></video>
                <canvas v-show="false" id="photoTaken" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
            </div>
            <div v-else class="camera-box">
                <img v-for="(item, index)  in items" :key="index" :width="canvasWidth" :height="canvasHeight"
                    :src="item.src" alt="hello">
            </div>
            <div class="d-flex justify-content-center mt-2">
                <template v-if="!captured">
                    <span class="bg-primary rounded-circle d-flex align-items-center justify-content-center"
                        style="height: 40px;width: 40px;">
                        <font-awesome-icon icon="fas fa-camera" class="text-white" size="lg"
                            @click="capture"></font-awesome-icon>
                    </span>
                </template>
                <template v-else>
                    <div class="row w-100">
                        <div class="col-6 p-0 pe-1">
                            <SecondaryButton @click="retakePhoto()">Retake</SecondaryButton>
                        </div>
                        <div class="col-6 p-0 ps-1">
                            <PrimaryButton @click="usePhoto()">Use</PrimaryButton>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {
    },
    data() {
        return {
            isCameraOpen: false,
            items: [],
            canvasHeight: 225,
            canvasWidth: 300,
            captured: false
        }
    },
    mounted() {
        this.toggleCamera()
        // this.canvasWidth = window.innerWidth - 48
        // this.canvasHeight = (this.canvasWidth) / 1.5
    },
    methods: {
        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.startCameraStream();
            }
        },
        startCameraStream() {
            const constraints = (window.constraints = {
                audio: false,
                video: { facingMode: { exact: 'environment' } },
                // video: true
            });
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.$refs.camera.srcObject = stream;
                }).catch(error => {
                    alert("Browser doesn't support or there is some errors." + error);
                });
        },
        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
            });
        },
        addToPhotoGallery(dataURI) {
            this.items.push(
                {
                    src: dataURI,
                    thumbnail: dataURI,
                    alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
                }
            )
            console.log(this.items)
        },
        capture() {
            const FLASH_TIMEOUT = 50;
            let self = this;
            setTimeout(() => {
                self.$refs.canvas.width = self.$refs.camera.videoWidth
                self.$refs.canvas.height = self.$refs.camera.videoHeight
                const context = self.$refs.canvas.getContext('2d');
                context.drawImage(self.$refs.camera, 0, 0);
                const dataUrl = self.$refs.canvas.toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");
                self.addToPhotoGallery(dataUrl);
                self.isCameraOpen = false;
                self.stopCameraStream();
                self.captured = true
            }, FLASH_TIMEOUT);
        },
        retakePhoto() {
            this.captured = false
            this.items = []
            this.toggleCamera()
        },
        usePhoto() {
            let dataURL = this.items[0].src
            let uniquePictureName = this.generateCapturePhotoName();
            let capturedPhotoFile = this.dataURLtoFile(dataURL, uniquePictureName + '.jpg')
            this.$emit('capturedPhoto', {
                preview: dataURL,
                file: capturedPhotoFile
            })
            // console.log(captur edPhotoFile)
            // Upload image api
            // axios.post('http://your-url-upload', formData).then(response => {
            //     console.log(response)
            // })
        },

        generateCapturePhotoName() {
            return Math.random().toString(36).substring(2, 15)
        },

        dataURLtoFile(dataURL, filename) {
            let arr = dataURL.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
    }
}
</script>
<style scoped>
.camera-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 5% auto;
    position: relative;
}

video {
    width: 300px;
    height: 225px;
    object-fit: cover;
    background: rgba(0, 0, 0, 0.2);
}
</style>