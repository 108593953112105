import axios from 'axios';
import store from '@/store';
import router from '@/router';

const API_URL = 'https://aladdin.micropos.app/api/agent/'

axios.interceptors.request.use(
  function(request) {
    return request
  }, 
  function(error) {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  function(response) {
    return response
  }, 
  function (error) {
    store.state.index.loading = 0
    if (error.code == "ERR_NETWORK") {
      store.dispatch('showAlert', {
        'message': "Network Error",
        'type' : 'error'
      })
    }else if(error.response.status == 500) {
      store.dispatch('showAlert',{
        'message': "Internal Server Error",
        'type' : 'error'
      })
    } else if (error.response.status == 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');

      // window.location.reload()
      router.push({ name: "login" })
    }

    return Promise.reject(error)
  }
);

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        phone: user.phone,
        password: user.password,
      }).then(response => {
        if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
            localStorage.setItem('token',response.data.token)
          }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  register(user) {
    return axios.post(API_URL + 'register', user, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      return response.data;
    });
  }
}

export default new AuthService();