<template>
    <div v-show="!isEditingPaymentInfo">
        <div class="p-4 d-flex flex-column" style="height: 94vh;">
            <h1 class="fw-700 fs-18">Unit အရောင်း</h1>
            <p class="fw-400 fs-13">အချက်အလက်များစစ်ဆေးနေဆဲ...</p>


            <div class="rounded-4 p-3 mb-3 border border-2 bg-white">
                <div class="d-flex">
                    <div>
                        <span class="fw-400 fs-13">ရောင်းပြီး</span>
                        <p class="fw-600 fs-13 mb-0 mt-2">350,000 Unit</p>
                    </div>
                    <div class="text-end ms-auto">
                        <span class="fw-400 fs-13">ကျန်ရှိ</span>
                        <p class="fw-600 fs-13 mb-0 mt-2 ">150,000 Unit</p>
                    </div>
                </div>

                <div class="progress-bar rounded">
                    <div class="progress" style="background-color: #F4A925;width: 70%;"></div>
                </div>
            </div>

            <div>
                <FormGroup :readOnly="true" :modelValue="user.minimum_amount" label="ရောင်းမည့်ပမာဏအနဲဆုံး"
                    appendText="Unit">
                </FormGroup>
                <FormGroup :readOnly="true" :modelValue="user.maximum_amount" label="ရောင်းမည့်ပမာဏအများဆုံး"
                    appendText="Unit">
                </FormGroup>
            </div>

            <div>
                <h1 class="fw-700 fs-14">Pay အမျိုးအစား</h1>
                <div class="mt-2">
                    <div v-for="(payment, index) in user.payment_methods" :key="index" class="mb-3">
                        <div class="d-flex align-items-center mb-2">
                            <img :src="payment.image" style="width: 40px;" class="me-3">
                            <div>
                                <div v-if="payment.receiver_account_name && payment.receiver_account_phone">
                                    <h1 class="fw-700 fs-14 mb-0 text-decoration-none text-dark">{{
                                        payment.receiver_account_name }}</h1>
                                    <p class="fw-400 fs-12 mb-0">{{ payment.receiver_account_phone }}</p>
                                </div>
                                <h1 v-else class="fw-700 fs-14 mb-0 text-decoration-none text-dark">{{ payment.name }}</h1>

                            </div>

                        </div>
                        <PrimaryButton
                            v-if="payment.receiver_account_name == null && payment.receiver_account_phone == null"
                            @click="editPayment(payment)">အချက်အလက်ဖြည့်မည်</PrimaryButton>
                    </div>
                </div>

            </div>


            <SecondaryButton v-if="user.current_mode_approved" class="fw-700 fs-14 mt-auto"
                @click="$router.push({ name: 'sell.create' })">ပြုပြင်မည်
            </SecondaryButton>
        </div>
    </div>
    <div v-if="isEditingPaymentInfo" class="p-4 d-flex flex-column" style="height: 94vh;">
        <edit-payment-info @updatePaymentInfo="updatePaymentInfo" :payment="editingPayment"></edit-payment-info>
    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
export default {
    components: {
        PrimaryButton
    },
    data() {
        return {
            user: this.$store.state.auth.user,
        }
    },
}
</script>

<style scoped>
.progress-bar {
    background-color: #ECF2FD;
}

.progress-bar,
.progress {
    height: 8px;
}
</style>