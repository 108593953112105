<template>
    <div class="p-4 pb-0" style="overflow: scroll;">
        <form @submit="handleRegister()" class="d-flex flex-column">
            <div>
                <h1 class="title mb-3">Register</h1>
                <p class="subtitle mb-4">အကောင့်ပြုလုပ်ရန် သင့်ဖုန်းအချက်အလက်ရိုက်ထည့်ပါ</p>

                <div>
                    <div class="d-flex flex-wrap mb-4 align-items-center">
                        <div class="col-5">
                            <img v-if="form.profile" :src="preview.profile" class="profile-image rounded-circle" alt="">
                            <div v-else class="profile-image rounded-circle">
                                <img src="@/assets/user.svg" class="rounded-circle" alt="">
                            </div>
                            <input ref="chooseImage" type="file" class="d-none" @change="imageChoosed('profile')">
                        </div>
                        <div class="col-6">
                            <SecondaryButton type="button" @click="takePhoto('profile')" class="mb-2 fw-600 fs-14">Take
                                Photo
                            </SecondaryButton>
                            <SecondaryButton type="button" class="position-relative" >
                                <input ref="chooseImage" type="file" class="hidden-input" @change="imageChoosed('profile')" />
                                Upload Photo
                            </SecondaryButton>
                        </div>
                        <div class="col-12">
                            <small class="text-danger fw-400 fs-12" v-for="(message, index) in errors.image" :key="index">{{
                                message }}</small>
                        </div>
                    </div>
                    <FormGroup :required="true" v-model="form.name" :errors="errors.name" label="Name"
                        placeholder="Enter your name" name="name" type="text">
                    </FormGroup>
                    <FormGroup v-model="form.phone" :errors="errors.phone" label="Phone Number" placeholder="09123456789"
                        type="number">
                    </FormGroup>
                    <div>
                        <label for="contact_number">Contact Phone Number</label>
                        <div class="fw-400 fs-12 mb-2">
                            <img src="@/assets/notice.svg" alt="">
                            Senior Agent မှသင့်အားဆက်သွယ်ရန်ဖုန်းနံပါတ်
                        </div>
                        <FormGroup :required="true" v-model="form.contact_phone" placeholder="Enter your phone number">
                        </FormGroup>
                    </div>
                    <div class="mb-3">
                        <label for="">NRC Photo</label>
                        <div class="row d-flex">
                            <NrcFrontVue>
                            </NrcFrontVue>
                            <NrcBackVue>
                            </NrcBackVue>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <input v-model="form.has_level_2" type="checkbox" class="me-2">
                        <label for="" class="fw-400 fs-14">Pay လက်ခံရန် Level 2 အကောင့်ရှိပါသည်</label>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <input required type="checkbox" class="me-2">
                        <label for="" class="fw-400 fs-14">I do agree Terms & Conditions</label>
                    </div>
                </div>
                <PrimaryButton type="submit" class="login-btn mt-auto mb-5">Agent အဖြစ်လျှောက်မည်
                </PrimaryButton>
            </div>
        </form>

        <ModalComponent :show="cameraId">
            <template v-slot:header>
                <span class="text-capitalize">{{ cameraId }}</span>
            </template>
            <template v-slot:body>
                <CameraComponent @capturedPhoto="capturedPhoto"></CameraComponent>
            </template>
        </ModalComponent>
    </div>
</template>
<script>
import ModalComponent from '@/components/ModalComponent.vue';
import CameraComponent from '@/components/CameraComponent.vue';
import NrcBackVue from './NrcBack.vue';
import NrcFrontVue from './NrcFront.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        ModalComponent, CameraComponent, NrcBackVue, NrcFrontVue
    },
    data() {
        return {
            cameraId: null,
            form: {
                phone: null,
                name: null,
                contact_phone: null,
                profile: null,
                nrc_front: null,
                nrc_back: null,
                has_level_2: false
            },
            preview: {
                profile: null,
                nrc_front: null,
                nrc_back: null
            },
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['getdata', 'getfront'])
    },
    methods: {
        next() {
            this.$router.push({
                name: 'new-pin',
                params: {
                    phone: this.phone,
                    name: this.name
                }
            })
        },
        takePhoto() {
            // console.log(text);
            const inputElement = this.$refs.chooseImage;

            // Clear previously selected files, if any
            inputElement.value = '';

            // Set the capture attribute to "camera" to show only the camera option
            inputElement.setAttribute("capture", "camera");

            // Trigger the click event on the input element
            inputElement.click();
        },
        getOTP() {
            console.log(this.OTPSeconds)
            var otpInterval = setInterval(() => {
                this.OTPSeconds--;

                if (this.OTPSeconds <= 0) {
                    clearInterval(otpInterval)
                    this.OTPSeconds = 60;
                    this.OTPLabel = "Get OTP"
                }

                this.OTPLabel = this.OTPSeconds + "s"
            }, 1000)
        },
        chooseImage(id) {
            this.$refs[id + '-input'].click();
        },
        imageChoosed(id) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.preview[id] = e.target.result;
            };
            this.form[id] = event.target.files[0]

        },
        handleRegister() {
            event.preventDefault();
            let data = new FormData();
            data.append('image', this.form.profile);
            data.append('phone', this.form.phone);
            data.append('contact', this.form.contact_phone);
            data.append('name', this.form.name);
            data.append('nrc_front', this.getfront)
            data.append('nrc_back', this.getdata)
            data.append('has_level_2', this.form.has_level_2)
            this.$store.dispatch('loading')
            this.$store.dispatch('auth/register', data).then((data) => {
                console.log(data)
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$router.push({ name: 'pending' })
                }
            }).catch((response) => {
                console.log(response)
                if (response.status == 422) {
                    this.errors = response.data.errors
                    if (this.errors.phone && this.errors.phone.length > 0) {
                        this.step = 1;
                    }
                }
            })
        },
        capturedPhoto(data) {
            this.form[this.cameraId] = data.file
            this.preview[this.cameraId] = data.preview
            this.cameraId = null
        }
    },
}
</script>

<style scoped>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}

.choose-nrc {
    height: 120px;
}

.choose-nrc .choose {
    background-color: #F1F3FA;
    height: 100%;
}

input[type='checkbox'] {
    width: 20px;
    height: 20px;
}
.hidden-input {
    position: absolute;
    opacity: 0;
}
</style>
