<template>
    <div class="home">
        <div class="home-banner p-4">
            <div class="d-flex justify-content-between">
                <div class="text-white">
                    <h1 class="banner-title">Aladdin 2D</h1>
                </div>
                <!-- <router-link :to="{ name: 'notifications' }">
                    <font-awesome-icon class="text-white" icon="fa-solid fa-bell" size="lg"></font-awesome-icon>
                </router-link> -->
            </div>
        </div>


        <div v-if="logined" class="welcome-box rounded-4 mx-3 mb-3 pb-3">
            <div class="d-flex align-items-center user-info bg-primary p-3">
                <div>
                    <img :src="currentUser.image" width="40" height="40" alt="">
                </div>
                <div class="text-white text-start ms-2">
                    <h1 class="user-name mb-1">{{ currentUser.name }}</h1>
                    <p class="user-phone mb-0">{{ currentUser.phone }}</p>
                </div>
                <div class="user-info-edit rounded-circle ms-auto">
                    <font-awesome-icon class="text-white" icon="fa-solid fa-pencil"></font-awesome-icon>
                </div>
            </div>

            <div>
                <div class="user-balance-info text-start p-3 d-flex align-items-center">
                    <div>
                        <p class="user-phone mb-0">Available Balance</p>
                        <h1 class="user-balance mb-0">{{ currentUser.balance ? $helper.formattedNumber(currentUser.balance)
                            : 0 }} Unit</h1>
                    </div>
                    <!-- <router-link :to="{ name: 'home' }" class="ms-auto text-decoration-none">
                        <span class="ms-auto text-primary mb-0 user-balance-deposit-label">ငွေဖြည့်မည်</span>
                    </router-link> -->
                </div>

                <div class="user-actions row px-4 justify-content-around">
                    <div class="col-4">
                        <a v-if="(currentUser.current_mode == 1 || currentUser.current_mode_approved == 0 || currentUser.current_mode == 3)"
                            class="text-decoration-none text-secondary">
                            <div class="px-3">
                                <SecondaryButton :disabled="true" class="mb-1 action position-relative">
                                    <img src="@/assets/unit-out.svg" alt="">
                                </SecondaryButton>
                            </div>
                            <p class="mb-0 fw-400 fs-13 text-secondary">Unit ရောင်းရန်</p>
                        </a>
                        <router-link v-else class="text-decoration-none" :to="{ name: 'sell.list' }">
                            <div class="px-3">
                                <SecondaryButton class="mb-1 action position-relative">
                                    <span v-if="currentUser.selling_pendings_count > 0"
                                        class="position-absolute noti rounded-circle bg-danger px-1 text-white">{{
                                            currentUser.selling_pendings_count }}</span>
                                    <img src="@/assets/unit-out.svg" alt="">
                                </SecondaryButton>
                            </div>
                            <p class="mb-0 fw-400 fs-13 text-dark">Unit ရောင်းရန်</p>
                        </router-link>
                    </div>
                    <div class="col-4">
                        <a v-if="(currentUser.current_mode == 2 || currentUser.current_mode_approved == 0 || currentUser.current_mode == 3)"
                            class="text-decoration-none text-secondary">
                            <div class="px-3">
                                <SecondaryButton :disabled="true" class="mb-1 action position-relative">
                                    <span v-if="currentUser.buying_pendings_count > 0"
                                        class="position-absolute noti rounded-circle bg-danger px-1 text-white">{{
                                            currentUser.buying_pendings_count }}1</span>
                                    <img src="@/assets/unit-plus.svg" alt="">
                                </SecondaryButton>
                            </div>
                            <p class="mb-0 fw-400 fs-13 text-secondary">Unit ဝယ်ရန်</p>
                        </a>
                        <router-link v-else
                            :disabled="(currentUser.current_mode == 1 || currentUser.current_mode_approved == 0)"
                            class="text-decoration-none" :to="{ name: 'buy.list' }">
                            <div class="px-3">
                                <SecondaryButton class="mb-1 action position-relative">
                                    <span v-if="currentUser.buying_pendings_count > 0"
                                        class="position-absolute noti rounded-circle bg-danger px-1 text-white">{{
                                            currentUser.buying_pendings_count }}</span>
                                    <img src="@/assets/unit-plus.svg" alt="">
                                </SecondaryButton>
                            </div>
                            <p class="mb-0 fw-400 fs-13 text-dark">Unit ဝယ်ရန်</p>
                        </router-link>
                    </div>
                    <div class="col-4">
                        <router-link class="text-decoration-none" :to="{ name: 'transaction-history' }">
                            <div class="px-3">
                                <SecondaryButton class="mb-1 action">
                                    <img src="@/assets/clock.svg" alt="">
                                </SecondaryButton>
                            </div>
                            <p class="mb-0 fw-400 fs-13 text-dark">မှတ်တမ်း</p>
                        </router-link>
                    </div>
                </div>
            </div>

        </div>
        <div v-else class="welcome-box rounded-4 p-3 mx-3">
            <img src="@/assets/home-logo.svg">
            <h1 class="welcome-title">Welcome to Aladdin 2D</h1>
            <p class="subtitle">အကောင့်ဖွင့်ပါ (သို့) Log in ဝင်ပါ</p>
            <div class="d-flex">
                <router-link class="w-50 me-1 text-decoration-none" :to="{ name: 'login' }">
                    <SecondaryButton>Login</SecondaryButton>
                </router-link>
                <router-link class="w-50 me-1 text-decoration-none" :to="{ name: 'register' }">
                    <PrimaryButton>Register</PrimaryButton>

                </router-link>
            </div>
        </div>



        <template v-if="logined">
            <template v-if="currentUser.current_mode == 1">
                <div v-if="currentUser.current_mode_approved == 1" class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white"
                    @click="$router.push({ name: 'sell.create' })">
                    <div class="d-flex align-items-center ">
                        <div>
                            <h1 class="fw-700 fs-14">User ဆီ သို့ Unit ရောင်းရန်</h1>
                            <p class="fw-600 fs-13 text-primary mb-0">စာတင်မည်</p>
                        </div>
                        <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                    </div>
                </div>

                <div v-if="currentUser.current_mode_approved == 0" class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white"
                    @click="$router.push({ name: 'buy' })">
                    <div class="d-flex align-items-center ">
                        <div>
                            <h1 class="fw-700 fs-14">User ဆီမှ Unit ဝယ်ရန်</h1>
                            <p class="fw-600 fs-13 text-primary mb-0">အချက်အလက်များစစ်ဆေးနေဆဲ...</p>
                        </div>
                        <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                    </div>
                </div>

                <template v-else>
                    <div class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white" @click="$router.push({ name: 'buy' })">

                        <div class="d-flex align-items-center ">
                            <div>
                                <h1 class="fw-700 fs-14">User ဆီမှ Unit ဝယ်ရန်</h1>
                                <p class="fw-600 fs-13 text-primary mb-0">{{
                                    $helper.formattedNumber(currentUser.minimum_amount)
                                }}
                                    Unit
                                    to {{ $helper.formattedNumber(currentUser.maximum_amount) }} Unit</p>
                            </div>
                            <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                        </div>

                        <div class="d-flex mt-4">
                            <div>
                                <span class="fw-400 fs-13">ရောင်းပြီး</span>
                                <p class="fw-600 fs-13 mb-0 mt-2">350,000 Unit</p>
                            </div>
                            <div class="text-end ms-auto">
                                <span class="fw-400 fs-13">ကျန်ရှိ</span>
                                <p class="fw-600 fs-13 mb-0 mt-2 ">150,000 Unit</p>
                            </div>
                        </div>

                        <div class="progress-bar rounded">
                            <div class="progress" style="background-color: #F4A925;width: 70%;"></div>
                        </div>
                    </div>


                </template>
            </template>
            <template v-else-if="currentUser.current_mode == 2">
                <div v-if="currentUser.current_mode_approved == 1" class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white"
                    @click="$router.push({ name: 'buy.create' })">
                    <div class="d-flex align-items-center ">
                        <div>
                            <h1 class="fw-700 fs-14">User ဆီမှ Unit ဝယ်ရန်</h1>
                            <p class="fw-600 fs-13 text-primary mb-0">စာတင်မည်</p>
                        </div>
                        <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                    </div>
                </div>

                <div v-if="currentUser.current_mode_approved == 0" class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white"
                    @click="$router.push({ name: 'sell.preview' })">
                    <div class="d-flex align-items-center ">
                        <div>
                            <h1 class="fw-700 fs-14">User ဆီ သို့ Unit ရောင်းရန်</h1>
                            <p class="fw-600 fs-13 text-primary mb-0">အချက်အလက်များစစ်ဆေးနေဆဲ...</p>
                        </div>
                        <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                    </div>
                </div>

                <template v-else>
                    <div class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white" @click="$router.push({ name: 'sell' })">
                        <div class="d-flex align-items-center ">
                            <div>
                                <h1 class="fw-700 fs-14">User ဆီ သို့ Unit ရောင်းရန်</h1>
                                <p class="fw-600 fs-13 text-primary mb-0">{{
                                    $helper.formattedNumber(currentUser.minimum_amount)
                                }}
                                    Unit
                                    to {{ $helper.formattedNumber(currentUser.maximum_amount) }} Unit</p>
                            </div>
                            <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                        </div>

                        <div class="d-flex mt-4">
                            <div>
                                <span class="fw-400 fs-13">ရောင်းပြီး</span>
                                <p class="fw-600 fs-13 mb-0 mt-2">350,000 Unit</p>
                            </div>
                            <div class="text-end ms-auto">
                                <span class="fw-400 fs-13">ကျန်ရှိ</span>
                                <p class="fw-600 fs-13 mb-0 mt-2 ">150,000 Unit</p>
                            </div>
                        </div>

                        <div class="progress-bar rounded">
                            <div class="progress" style="background-color: #F4A925;width: 70%;"></div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white" @click="$router.push({ name: 'buy.create' })">
                    <div class="d-flex align-items-center ">
                        <div>
                            <h1 class="fw-700 fs-14">User ဆီမှ Unit ဝယ်ရန်</h1>
                            <p class="fw-600 fs-13 text-primary mb-0">စာတင်မည်</p>
                        </div>
                        <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                    </div>
                </div>

                <div v-if="currentUser.current_mode == 3" class="rounded-4 p-3 mx-3 mb-3 border border-2 bg-white"
                    @click="$router.push({ name: 'sell.create' })">
                    <div class="d-flex align-items-center ">
                        <div>
                            <h1 class="fw-700 fs-14">User ဆီ သို့ Unit ရောင်းရန်</h1>
                            <p class="fw-600 fs-13 text-primary mb-0">စာတင်မည်</p>
                        </div>
                        <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
                    </div>
                </div>
            </template>


        </template>

        <router-link :to="{ name: 'rules' }"
            class="home-info text-decoration-none rounded-4 d-flex align-items-center p-3 mx-3 mb-3">
            <img src="@/assets/rule.svg" class="me-3" alt="">
            <h1 class="fw-700 fs-14 mb-0 text-decoration-none text-dark">Agent စည်းမျဥ်းစည်းကမ်းများ</h1>
            <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
        </router-link>

        <router-link :to="{ name: 'commisions' }"
            class="home-info text-decoration-none rounded-4 d-flex align-items-center p-3 mx-3 mb-3">
            <img src="@/assets/commision.svg" class="me-3" alt="">
            <h1 class="fw-700 fs-14 mb-0 text-decoration-none text-dark">ကော်မရှင်ခ</h1>
            <img src="@/assets/arrow-right.svg" class="ms-auto" alt="">
        </router-link>


    </div>
</template>
<script>

export default {
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,

            bannerText: null,
            bannerImages: []
        }
    },
    created() {
        console.log(this.currentUser)
    }
}
</script>
<style scoped>
.home-banner {
    height: 25vh;
    width: 100%;
    background: url('../assets/top-background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.progress-bar {
    background-color: #ECF2FD;
}

.progress-bar,
.progress {
    height: 8px;
}

.home-info {
    background-color: #ECF2FD !important;
}

.banner-title {
    font-weight: 600;
    font-size: 16px;
}

.welcome-box {
    margin-top: -30px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #6D7FA333;
    text-align: center;
}

.welcome-box .user-info {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
}

.ad-box {
    border: 2px solid #ECEEF5;
    border-radius: 14px;
}

.ad-box img {
    border-radius: 14px 14px 0px 0px;
    height: 25vh;
}

.ad-box .ad-description {
    font-size: 13px;
    font-weight: 400;
}

.ad-box .ad-description-box {
    background-color: #447BE833;
    padding-top: 5px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.user-balance-deposit-label {
    font-weight: 400;
    font-size: 12px;
}

.user-name {
    font-weight: 600;
    font-size: 14px;
}

.user-phone {
    font-weight: 400;
    font-size: 12px;
}

.user-balance {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}

.user-info-edit {
    background: #00000033;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-box .welcome-title {
    font-weight: 600;
    font-size: 18px;
}

.games-box .title {
    font-weight: 700;
    font-size: 16px;
}

.user-actions .action {
    aspect-ratio: 1/1;
}

/* 
.home {
    margin-bottom: 15vh !important;
} */

.noti {
    right: -10px;
    top: -10px;
    line-height: 24px;
    width: 24px;
    height: 24px;
}
</style>
