<template>
    <div>
        <div class="d-flex winners-nav border-0">
            <a @click="type = '2d'" class="w-50 winner-nav-item" :class="{
                active: type == '2d'
            }">2D</a>
            <a @click="type = '3d'" class="w-50 winner-nav-item" :class="{
                active: type == '3d'
            }">3D</a>
        </div>
        <template v-if="type == '3d'">
            <ThreeDCommisions></ThreeDCommisions>
        </template>
        <template v-else>
            <TwoDCommisions></TwoDCommisions>
        </template>
    </div>
</template>
<script>
import TwoDCommisions from '@/components/TwoDCommisions.vue';
import ThreeDCommisions from '@/components/ThreeDCommisions.vue';

export default {
    components: {
        TwoDCommisions, ThreeDCommisions
    },
    data() {
        return {
            type: "2d"
        }
    },
    mounted() {
        if (this.$route.params.type) {
            this.type = this.$route.params.type
        }
    }
}
</script>
<style>
.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    color: white !important;
    background-color: #2B5DDC !important;
}
</style>