import userService from "@/services/user.service";

export const cash = {
    namespaced: true,
    state: {
        selectedTopUpAmount: localStorage.getItem('selectedTopUpAmount') ?? 0,
         paymentMethods: [
        ]
    },
    actions: {
        chooseSelectedTopUpAmount({ commit },amount) {
            commit('chooseSelectedTopUpAmount',amount);
        }, 
        getPaymentMethods({ commit }) {
            console.log('here')
            return userService.getPaymentMethods().then(
                response => {
                commit('updatePaymentMethods', response.data);
                return Promise.resolve(response);
                },
                error => {
                return Promise.reject(error);
                } 
            )
        }
    },
    mutations: {
        chooseSelectedTopUpAmount(state, amount) {
            state.selectedTopUpAmount = amount
            localStorage.setItem('selectedTopUpAmount',amount)
        },
        updatePaymentMethods(state,data) {
            state.paymentMethods = data.data
            console.log(state.paymentMethods)
        }
    }
}