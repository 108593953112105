import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const token = localStorage.getItem('token');

const initialState = user
  ? { status: { loggedIn: true }, user, token }
  : { status: { loggedIn: false }, user: null , token:null };
  

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user.data, user.token);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess', response.data);
          return Promise.resolve(response);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    userInfo({ commit },user) {
      commit('updateUserInfo',user)
    }
  },
  mutations: {
    loginSuccess(state, user, token) {
      state.status.loggedIn = true;
      state.user = user;
      state.token = token
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    updateUserInfo(state, data) {
      state.user = data.data
      localStorage.setItem('user', JSON.stringify(state.user));
    }
  }
};