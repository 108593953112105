<template>
    <div class="pending d-flex flex-column">
        <div class="text-center mt-auto p-4">
            <img src="@/assets/check.svg" class="mb-4" alt="">
            <h1 class="fw-600 fs-18 mb-3">အချက်အလက်များ စစ်ဆေးနေပါသည်</h1>
            <p class="fw-400 fs-13">{{ note }}</p>
        </div>
        <div class="background text-center d-flex flex-column">
            <div class="fw-400 fs-14 text-decoration-underline  mb-5 mt-auto" @click="showModal = true">အကူအညီ</div>
        </div>
        <modal-box @close="showModal = false" :show="showModal">
            <template v-slot:header>အကူအညီ</template>

            <template v-slot:body>
                <ul class="list-group list-group-flush">
                    <li v-for="(help, index) in helps" :key="index" class="list-group-item d-flex align-items-center">
                        <font-awesome-icon :icon="help.icon" size="lg" class="me-3 text-primary" />
                        <div>
                            <p class="fw-400 fs-12 mb-1 text-capitalize">{{ help.type }}</p>
                            <h1 class="fw-600 fs-14 mb-0">{{ help.phone }}</h1>
                        </div>
                        <span class="fw-500 fs-13 ms-auto text-primary">{{
                            help.type == 'call' ? 'ဖုန်းခေါ်မည်' :
                            'စကားပြောမည်'
                        }}</span>
                    </li>
                </ul>
            </template>
        </modal-box>
    </div>
</template>
<script>
import ModalBox from '@/components/ModalComponent.vue';

export default {
    components: {
        ModalBox
    },
    data() {
        return {
            showModal: false,
            note: "စစ်ဆေးချိန် \n နာရီဝက်ခန့်ကြာမြှင့်နိုင်ပြီး Agent ဖြစ်ပါက သင့်ဖုန်းနံပါတ်ကိုဆက်သွယ်အကြောင်းကြားပါမည်"
        }
    }
}
</script>
<style scoped>
.pending {
    height: 100vh;
    background: #3367EE;
    color: white;
}

.background {
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: url('../assets/background.svg');
}
</style>